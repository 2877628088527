.text-field-component {
	font-family: "Inter", sans-serif;
	font-style: normal;
}

.text-field-component h5 {
	font-size: 16px;
	font-weight: 600;
	color: #000000;
}
.text-field-component h6 {
    font-size: 15px;
}
.text-field-component .answer-text {
	font-family: "Work Sans", sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #011f3b;
}
