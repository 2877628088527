.container-presentation-page {
  border-radius: 20px;
  background: #fff;
  padding: 30px;
}
.presenation-page-content {
  width: 60%;
  margin: auto;
}
.title-presentation-page-content {
  color: #f39200;
  text-align: center;
  font-family: "Inter";
  font-size: 39.35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.text-presentation-page {
  color: #1f497d;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.title-text-presentation-page {
  color: #1f497d;
  font-family: "Inter";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.container-presentaion-personnage {
  display: flex;
  align-items: center;
  gap: 50px;
}
.nom-personnage {
  color: #1f497d;
  font-family: "Poppins";
  font-size: 61px;
  font-style: normal;
  font-weight: 700;
  line-height: 129.4%;
  position: relative;
}
.nom-personnage::after {
  content: "";
  position: absolute;
  width: 110px;
  height: 5px;
  background: #f39200;
  bottom: 0;
  left: 0;
}
.text-description-presentation-personnage {
  color: rgba(0, 0, 0, 0.75);
  text-align: justify;
  font-family: "Poppins";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.nav-link-presentation-personnage {
  width: 18px;
  height: 5px;
  background-color: #d3d3d3;
  padding: 0;
  margin-left: 15px;
}
.nav-link-presentation-personnage.active {
  background-color: #f39200 !important;
  padding: 0;
  width: 18px;
  height: 5px;
  border-color: none !important;
}
.container-title-page-presentation-personnage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-theme-outline {
  color: #4f4f4f;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 55px;
  padding: 0.8rem 3rem;
  border: 1px solid #f39200;
  transition: 0.2s ease-in-out;
  background: #fff;
}
.btn-theme-outline:hover {
  background-color: #f39200;
  color: #fff;
}
.sub-title-sous-modules {
  color: rgba(0, 0, 0, 0.75);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.container-title-sous-modules {
  background-color: #f5f5f5;
  padding: 20px;
}
.title-choisir-personnage {
  color: #1f497d;
  font-family: "Inter";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.text-title-choisir-personnage {
  color: #011f3b;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.name-personnage-check {
  color: #1f497d;
  font-family: "Poppins";
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 129.4%;
  text-align: center;
}
.poste-personnage-check {
  color: #1f497d;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 129.4%;
}
.content-img-check-personnage {
  display: flex;
  justify-content: center;
}
.col-choisir-personnage {
  display: flex;
}
/* css checkbox */

.form {
  position: relative;
}
.input-check-personnage {
  transition: linear 0.8s;
  height: 0;
  width: 0;
}
.label-choisir-personnage,
.input-check-personnage[type="radio"] {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.label-choisir-personnage {
  border-radius: 0.75em;
  padding: 1.5em;
  transition: background-color 0.3s;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
}
.form .input-check-personnage[type="radio"]:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  top: 20px;
  right: 30px;
  transition: linear 0.2s;
  cursor: pointer;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.form .input-check-personnage[type="radio"]:checked:after {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  background: #f39200;
  transition: linear 0.2s;
  cursor: pointer;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 9;
}

.form .input-check-personnage[type="radio"]:checked:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 8px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  margin-top: 5px;
  top: 24px;
  right: 36px;
  z-index: 10;
  cursor: pointer;
}
.form .input-check-personnage[type="radio"]:checked + .span-check-personnage {
  background-color: #fff;
  border-radius: 20px;
  border: 6px solid #f39200;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 15px;
  top: 0;
  right: 15px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* css checkbox */
.img-personnage {
  width: 150px;
}

.img-check-personnage {
  /* width: 90px; */
  height: 220px;
}
.stepper-horizontal-container {
  position: absolute;
  top: 6%;
}

.stepper-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 5px;
  background: #ffff;
  margin-left: 10px;
  margin-right: 10px;
}

.stepper-item.active {
  background-color: #f39200;
}
.container-header-sous-module {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-images-modules-v4 {
  width: 100%;
}
.img-modules-v4 {
  width: 100%;
  height: 229px;
}
